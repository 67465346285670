<template>
    <el-scrollbar class="exam-content">
        <div class="main-content">
            <div class="top-content">
                <!--                <i class="iconfont">&#xe619;</i>-->
                <!--                <span class="top-title" v-if="!isStart">距离实训开始：</span>-->
                <!--                <span class="top-title" v-else>距离实训结束：</span>-->
                <!--                <span class="top-time">{{time}}</span>-->
                <CountDown @examStatus="examStatus"></CountDown>
            </div>
            <div class="middle-content">
                <div class="title-item title-left"></div>
<!--                <div class="title-text">实训标题</div>-->
                <div class="title-text">{{returnTrainText}}</div>
                <div class="title-item title-right"></div>
            </div>
            <div class="img-content">
                <img v-if="!isStart" src="../../assets/image/student/not_start.png" alt="">
                <img v-else src="../../assets/image/student/exam_start.png" alt="">
                <div v-if="!isStart" class="tip-content">
                    <div class="tip-top">实训未开始</div>
                    <div class="tip-top">敬请期待</div>
                </div>
                <div v-else class="footer-content">
                    <el-button type="primary" round @click="gotoExam">进入实训</el-button>
                    <el-button type="primary" round @click="gotoLive">查看他人直播</el-button>
                </div>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
    import CountDown from "components/CountDown";

    export default {
        name: "Exam",
        components: {
            CountDown
        },
        data() {
            return {
                time: '00:30:44',
                isStart: true,
            }
        },
        computed: {
          returnTrainText: function () {
              return localStorage.getItem('train_name')
          }
        },
        methods: {
            gotoExam() {
                this.$router.push('/liveSelection')
            },
            gotoLive(){
                this.$router.push('/otherLiveRoomList')
            },
            examStatus(val) {
                this.isStart = val
            }
        }
    }
</script>

<style scoped lang="scss">
    .exam-content {
        height: 100%;
        width: 100%;
        background: linear-gradient(56deg, #070932 0%, #12144D 100%);

        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;

            .el-scrollbar__view {
                height: 100%;
            }
        }

        .main-content {
            width: 500px;
            margin: 0 auto;
            padding-top: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .top-content {
        font-size: 18px;
        display: flex;
        align-items: center;

        i {
            font-size: 22px;
            color: #FF3E6C;
            margin-right: 11px;
        }

        .top-title {
            height: 24px;
            color: #FFFFFF;
        }

        .top-time {
            color: #FF3E6C;
            height: 24px;
            line-height: 28px;
        }
    }

    .middle-content {
        width: calc(100% - 72px);
        margin: 50px 36px 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title-text {
            font-size: 30px;
            font-weight: 500;
            color: #FFFFFF;
        }
    }

    .title-item {
        width: 94px;
        height: 1px;
        background: #FFFFFF;
        border: 1px solid #FFFFFF;
        position: relative;

        &.title-left:after {
            position: absolute;
            content: '';
            height: 13px;
            width: 13px;
            border-radius: 50%;
            right: -2px;
            top: -5px;
            background-color: #fff;
        }

        &.title-right:before {
            position: absolute;
            content: '';
            height: 13px;
            width: 13px;
            border-radius: 50%;
            left: -2px;
            top: -5px;
            background-color: #fff;
        }
    }

    .img-content {
        position: relative;

        .tip-content {
            position: absolute;
            width: 100%;
            left: 0;
            top: 50%;
            margin-top: -50px;
            font-size: 30px;
            color: #0E1042;
            font-weight: 500;
            line-height: 48px;

            .tip-top {
                text-align: center;
            }
        }
    }

    .footer-content {
        width: calc(100% - 72px);
        margin: -60px 36px 0;
        display: flex;
        justify-content: space-between;

        .el-button {
            width: 200px;
        }
    }

    .count-down {
        background-color: unset;
    }
</style>